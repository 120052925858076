.btn-primary {
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #308EC3;
    color: #FFF;
    font-family: Barlow;
    font-size: 20px;
    font-weight: 400;
    border: none;
    cursor: pointer;
}

.btn-secondary {
    color: #414141;
    font-size: 20px;
    font-weight: 400;
    text-decoration-line: underline;
    border: none;
    background: #FFF;
    cursor: pointer;
}

.btn-disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(207, 191, 200, 0.8) !important;
}

.go-to-login-btn {
    color: #308EC3;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
}

.go-to-login-wrapper {
    text-align: center;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    
}

.password-info-btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border: 1px solid #FFF;
    border-radius: 50%;
    background-color: transparent;
    color: #FFF;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
    text-align: center;
}

.form-button {
    border: none;
    color: #FFF;
    background-color: #1E9DF2;
    padding: 15px 50px;
    border-radius: 2px;
    margin: 30px auto 30px auto;
    display: block;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    min-width: 200px;
}
