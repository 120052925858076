.confirmed-reg-page {
    font-family: "IBM Plex Sans";
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmed-reg-content-wrapper {
    display: flex;
    align-items: center;
    margin-top: auto;
    justify-content: space-evenly;
    width: 100%;
}

.success-confirmed-reg-wrapper {
    z-index: 1;
    width: 100%;
    padding: 60px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    min-height: 400px;
    max-width: 400px;
}

.success-confirmed-reg {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: 100%;
}

.success-confirmed-text {
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    font-size: 26px;
    font-weight: 500;
    width: 100%;
}

.check-email-btn {
    border: none;
    color: #FFF;
    background-color: #1E9DF2;
    padding: 15px 50px;
    border-radius: 2px;
    margin: 30px auto 10px auto;
    display: block;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}