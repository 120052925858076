.download-as-btn {
    border-radius: 100px;
    padding: 5px 10px;
    border: 1px solid #308EC3;
    cursor: pointer;
    max-width: 150px;
    width: 100%;
}

.download-as-btn:focus {
    outline: none;
}