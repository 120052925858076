.download-wrapper {
    z-index: 1;
    width: 100%;
    padding: 30px;
    border-radius: 30px;
    background: rgba(255, 255, 255, .2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    min-height: 400px;
    max-width: 400px;
    text-align: center;
}

.download-file-title {
    font-family: "IBM Plex Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
}

.download-file-info {
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    font-size: 24px;
    font-weight: 500;
}

.btn-primary.download {
    border-radius: 0;
}

.btn-secondary.download {
    color: #1E9DF2;
    background-color: transparent;
}