.create-password-page {
    font-family: "IBM Plex Sans";
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-pass-content-wrapper {
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: space-evenly;
  width: 100%;
}

.create-password-form {
  z-index: 1;
  width: 100%;
  padding: 60px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  min-height: 400px;
  max-width: 400px;
}

.create-password-title {
    font-family: "IBM Plex Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}

.change-pass-btn-wrapper {
    width: 100%;
}

.change-pass-button {
    border: none;
    color: #FFF;
    background-color: #1E9DF2;
    padding: 15px 50px;
    border-radius: 2px;
    margin: 30px auto 10px auto;
    display: block;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.password-create-tip {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.password-wrapper, .confirm-password-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.password:focus, .confirm-password:focus {
    background: url('../../assets/icons/passwordIcon.svg') no-repeat scroll #D9D9D9 8px 8px;
}

.password, .confirm-password {
    height: 40px;
    background: url('../../assets/icons/passwordIcon.svg') no-repeat scroll #D9D9D9 8px 8px;
    padding-left: 40px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 2px;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;
}

.pass-changed {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: 100%;
}

.pass-changed-success-text {
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    font-size: 26px;
    font-weight: 500;
    max-width: 250px;
    margin: 20px auto 0 auto;
}

.pass-changed-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: 100%;
}

.create-password-label {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 25px 0 5px 0;
    margin: 0;
    display: flex;
    gap: 10px;
}
