@media screen and (max-width: 768px) {
    .page-footer {
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
    }
}

@media screen and (min-width: 769px) {
    .page-footer {
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 10px;
        margin-bottom: 0;
        margin-top: auto;
        gap: 10px;
    }
}

.page-footer {
    z-index: 1;
    display: flex;
    color: #FFFFFF;
    width: 100%;

}