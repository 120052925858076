.choose-file-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.file-upload-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.icons-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    gap: 50px;
    div {
        min-width: 180px;
    }
}

.icon-text {
    color: #C4C4C4;
    font-size: 20px;
    font-weight: 400;
    padding: 15px 0 0 0;
}

.translate-file-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step-2-buttons {
    margin-top: 50px;
}

.text-step-2 {
    color: #414141;
    font-family: "IBM Plex Sans";
    font-size: 20px;
    font-weight: 600;
}

.loader-text {
    color: #414141;
}

.upl-file-text {
    color: #414141;
    font-family: "IBM Plex Sans";
    font-size: 30px;
    font-weight: 600;
}

.upl-file-hor-line {
    border: 1px solid #DCDCDC;
    width: 80px;
    margin: 0 auto 0 auto;
}