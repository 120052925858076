.button-save-to-esd-wrapper {
    border: 1px solid #308EC3;
    border-radius: 30px;
    max-width: 150px;
    overflow: hidden;
    width: 100%;
}

.save-to-esd {
    color: #414141;
    font-size: 14px;
    font-weight: 400;
    padding: 2px 10px;
}

.btn-add-translation-title {
    display: flex;
}

.btn-save-translation {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
    border: none;
    cursor: pointer;
    margin-right: 0;
    margin-left: auto;
}

.input-add-translation-title {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-right: none;
    border: none;
    font-size: 12px;
    width: 100%;
    padding-left: 10px;
}

.btn-saved-translation-final {
    color: #FFF;
    background-color: #308EC3;
    padding: 5px 10px;
}