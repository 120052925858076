.translator-dashboard-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.translator-dashboard-content {
    width: 100%;
    height: 100%;
}

.usage-dashboard {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    padding: 20px 0 20px 0;
    background-color: #FFFFFF;
    border-radius: 30px;
    margin: 50px auto auto auto;
}

.char-usage-container {
    color: #414141;
    font-size: 42px;
    font-weight: 600;
    max-width: 400px;
    width: calc(100% - 40px);
    margin: 0 auto 0 40px;
}

.usage-graph-bottom {
    display: flex;
    justify-content: space-between;
}

.char-usage-graph-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 40px;
}

.ch-used {
    margin-left: 0;
    margin-right: auto;
    font-weight: 700;
    font-size: 42px;
    color: #414141;
}

.usage-bar-wrapper {
    background-color: #DBE0E5; 
    width: 100%; 
    border-radius: 15px; 
    margin: 10px 0 10px 0;
    text-align: center;
}

.usage-bar {
    background-color: #308EC3;
    padding: 1%; 
    border-radius: 15px;
    position: relative; 
    span {
        color: #FFF;
    }
    
}

.limit-char {
    color: #414141;
    font-size: 20px;
    font-weight: 400;
}

.ch-left {
    color: #C4C4C4;
    font-size: 20px;
    font-weight: 400;
}

.translated-files-wrapper {
    width: 100%;
    height: auto;
    min-height: 200px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    max-width: 1200px;
    border-radius: 30px;
    margin: 50px auto 50px auto;
    padding: 20px 0 20px 0;
    position: relative;
}

.translated-left {
    max-width: calc(400px - 40px);
    width: 100%;
    margin: 0 auto 0 40px;
}

.v-line {
    height: 100%;
    border: 1px solid #F5F5F5;
    z-index: 50;
    position: absolute;
    top: 0;
    left: 401px;
}

.translated-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    padding: 0 20px 0 20px;
}

.translated-left-title {
    color: #414141;
    font-size: 32px;
    font-weight: 600;
    margin: 0;
}

.translated-left-files-count {
    color: #414141;
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0 0 0;
}

.new-tr-dashboard-btn {
    margin-top: 50px
}

.col-file-name, .col-file-created-at, .col-share-file, .col-file-download {
    display: flex;
    flex-direction: column;
}

.file-data-table {
    border-collapse: collapse;
    width: 100%;
    color: #414141;
    font-size: 18px;
}

tr {
    border-bottom: 1px solid #DCDCDC;
}

td {
    padding: 10px;
    font-weight: 400;
}

.first-column {
    width: 40%;
}

.fourth-column {
    text-align: right;
}

th {
    padding: 10px;
    text-align: start;
    font-weight: 700;
}

.copy {
    position: relative;
    cursor: pointer;

}

.copy:hover .copy-url-text {
    visibility: visible;
}

.copy-url-text {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100px;
    color: #000000;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
}

.copy-url-text::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: transparent;
    padding: 5px;
    z-index: 1;
} 

#top-tooltip {
    top: -250%;
    right: -250%;
}

#top-tooltip::before {
    top: 100%;
    left: 110%;
}

.MuiStack-root {
    margin: 20px 0 0 0 !important;
}