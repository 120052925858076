.translate-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.translator-page-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.translate-text-actions-wrapper, .translate-files-actions-wrapper {
    display: flex;
    border-radius: 10px;
    border: 1px solid #CEEDFF;
    align-items: center;
    gap: 10px;
    padding: 15px;
    cursor: pointer;
    background-color: #FFF;
}

.translator-top-actions {
    display: flex;
    max-width: 1200px;
    width: 100%;
    justify-content: flex-start;
    gap: 40px;
    border-radius: 30px;
    margin: 20px auto 20px auto;
}

.actions-text p:first-child {
    margin: 0 0 5px 0;
    color: #414141;
    font-family: Barlow;
    font-size: 18px;
    font-weight: 500;
}

.actions-text p:nth-child(2) {
    margin: 0;
    color: #414141;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 400;
}

.translator-wrapper {
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    text-align: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    max-height: 500px;
    margin: 0 auto 20px auto;
    // box-shadow: 0px 1px 4px rgba(0,0,0,.1);
    background: #FFF;
    .translator-elements {
        background-color: #FFF;
        display: flex;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        overflow: hidden;
        .source-textarea {
            display: flex;
            flex-direction: column;
            width: 50%;
            font-size: 26px;
            position: relative;
            border-bottom-left-radius: 5px;
            .delete-button-source {
                padding: 10px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 10px;
                right: 25px;
            }
        }


        .target-textarea {
            display: flex;
            flex-direction: column;
            width: 50%;
            position: relative;
            border-bottom-right-radius: 5px;
            .delete-button-target {
                padding: 10px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 10px;
                right: 10px;
            }

        }
  
        .vertical-line {
            display: flex;
            height: 490px;
            border: .5px solid #DCDCDC;
            margin: auto;
        }

    }
    .divider {
        height: 100%;
        width: 1px;
        background-color: lightgray;
    }

}

/* .btn-visible {
    visibility: visible !important; 
} */

  


.active {
    border-color: #308EC3;;
}

.translate-files-wrapper {
    display: flex;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    min-height: 490px;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    flex-direction: column;
}

.source-bottom-wrapper {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px;
    justify-content: space-between;
}

.usage-info {
    // position: absolute;
    // bottom: 20px;
    // left: 20px;
    color: #C4C4C4;
    font-size: 20px;
    font-weight: 400;
}

// .translate-btn {
//     position: absolute;
//     bottom: 20px;
//     right: 20px;
// }

.target-actions-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    bottom: 5px;
    gap: 20px;
    cursor: pointer;
    width: 100%;
    background-color: #FFF;
    max-width: 599px;
    box-sizing: border-box;
    border-bottom-right-radius: 30px;
    height: 80px;
}

.source-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    bottom: 5px;
    cursor: pointer;
    width: 100%;
    background-color: #FFF;
    max-width: 599px;
    box-sizing: border-box;
    border-bottom-left-radius: 30px;
    height: 80px;
}

.translate-btn:disabled {
    pointer-events: none;
}

.aligned-icons {
    display: flex;
    align-items: center;
}

.btn-rounded {
    border: 1px solid #308EC3;
    border-radius: 15px;
    padding: 5px 10px;
}

.new-translation {
    color: #414141;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
}

.download-as-pdf {
    color: #414141;
    font-size: 14px;
    font-weight: 400;
}

.copy, .share {
    position: relative;
}

.copy:hover .tooltip-text {
    visibility: visible;
}

.share:hover .tooltip-text {
    visibility: visible;
}

.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100px;
    color: #000000;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
}

.tooltip-text::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: transparent;
    padding: 5px;
    z-index: 1;
}

#top {
    top: -250%;
    right: -180%;
}

#top::before {
    top: 100%;
    left: 110%;
}

.textarea-empty-info-text-wrapper {
    position: absolute;
    top: 60px;
    left: 20px;
    color: #C4C4C4;
    text-align: left;
    font-weight: 400;
    cursor: text;
}