@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&family=IBM+Plex+Sans:wght@100;200;300;400;500;600&display=swap');
@import './styles/utilities.scss';
@import './styles/buttons.scss';

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F7F8;
  height: 100vh;
  width: 100%;
}

.textarea-no-default-styles {
  position: relative;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  min-height: 380px;
  width: 100%;
  padding: 20px 60px 20px 20px;
  line-height: 1.5;
  font-size: 26px;
  color: #333333;
  font-family: "Barlow";
  border-radius: inherit;
  height: calc(100% - 84px);
  box-sizing: border-box;
}

p, div, span {
  font-family: Barlow;
}

@media screen and (max-width: 768px) {
  body {
    overflow: auto;
  }
}

@media screen and (min-width: 769px) {
  body {
    overflow: hidden;
  }
}

.validation-error-message {
  color: red;
  font-family: "IBM Plex Sans";
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0 0 0;
  position: absolute;
  bottom: -20px;
}

input::-ms-reveal {
  display: none;
}