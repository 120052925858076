.bouncing-loader-small {
    display: flex;
    margin: auto;
    gap: 10px;
    justify-content: center;
}

.dot-small {
    width: 10px;
    height: 10px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
  }
  
  .dot-small:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot-small:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot-small:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }