.signup-page {
    font-family: "IBM Plex Sans";
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-content {
    display: flex;
    align-items: center;
    margin-top: auto;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100vh;
}

.signup-content {
    display: flex;
}

.signup-left-col {
    z-index: 1;
    max-width: 350px;
}

.reg-info-1, .reg-info-2 {
    padding: 10px;
}
.signup-page-title {
    padding-bottom: 40px;
}

.reg-info-1 {
    margin-bottom: 20px;
}

.step {
    color: #308EC3;
    font-size: 23px;
    font-weight: 600;
    margin: 0 0 10px 0;
}

.step-description {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}



.signup-right-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    height: 100vh;
    z-index: 5;
    padding: 40px;
}

.singup-title {
    text-align: center;
}

.footer-signup {
    justify-content: unset;
    gap: 20px;
    position: fixed;
    bottom: 0;
    max-width: 380px;
}

.footer-signup img {
    width: 100px;
}

.signup-form {
    margin-bottom: auto;
}

.signup-form-title {
    color:  #FFF;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    margin-top: auto;
    margin-bottom: 0;
}

.signup-form-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: auto;
}

.signup-label-base {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 25px 0 5px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.signup-input-base {
    height: 40px;
    background-color: #D9D9D9;
    padding-left: 20px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 2px;
    border: none;
    border-radius: 10px;
    min-width: 300px;
    font-family: "IBM Plex Sans" !important;
    font-size: 16px;
}

.react-tel-input {
    height: 40px;
    background-color: #D9D9D9;
    padding-left: 20px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 2px;
    border: none;
    border-radius: 10px;
    min-width: 300px;
    font-family: "IBM Plex Sans" !important;
    font-size: 16px;
    width: unset !important;
}

.signup-base-field-wrapper {
    display: flex;
    gap: 40px;
}

.signup-field-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.flag-dropdown {
    background-color: #D9D9D9 !important;
}

input.form-control {
    background-color: #D9D9D9 !important;
    height: 100% !important;
    border: none !important;
    font-family: "IBM Plex Sans" !important;
    font-size: 16px !important;
    border-radius: 10px;
}

.flag-dropdown  {
    border: none !important;
}

.signup-button-wrapper {
    width: 100%;
}

.signup-password {
    height: 40px;
    background: url('../../assets/icons/passwordIcon.svg') no-repeat scroll #D9D9D9 10px 8px;
    padding-left: 40px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 2px;
    border: none;
    border-radius: 10px;
    min-width: 280px;
}

.signup-password:-internal-autofill-selected {
    // appearance: menulist-button;
    // background-image: none !important;
    // background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    // color: fieldtext !important;
    background: url('../../assets/icons/passwordIcon.svg') no-repeat scroll #D9D9D9 10px 8px;
}

.login-par {
    text-align: center;
    width: 100%;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.signup-success-wrapper {
    padding: 40px;
    z-index: 1;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    min-height: 300px;
    max-width: 400px;
    width: 100%;
}

.signup-confirmation-chboxes {
    display: flex;
    flex-direction: column;
    padding: 60px 0 0 20px;
    max-width: 650px;
    gap: 20px;
}

.signup-confirmation-chboxes label {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
}

.signup-confirmation-chboxes input {
    accent-color: #1E9DF2;
}

.privPolicy, .termsOfUse {
    padding: 0;
}

.priv-policy-wrapper, .terms-of-use-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.priv-policy-wrapper label, .terms-of-use-wrapper label {
    padding: 0;
}

.signup-input-base:-webkit-autofill,
.signup-input-base:-webkit-autofill:focus,
.form-control:-webkit-autofill, 
.form-control:-webkit-autofill:focus
.signup-password:-webkit-autofill,
.signup-password:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s !important;
}

.react-international-phone-input-container {
    margin-top: 5px;
}

.react-international-phone-country-selector-button {
    background-color: #D9D9D9 !important;
    height: 40px !important;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
}