.loader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px auto 20px auto;
    gap: 20px
}

.bouncing-loader {
    display: flex;
    margin: 20px auto 20px auto;
    gap: 20px;
    justify-content: center;
}

.loader-text {
    display: flex;
    margin: 20px auto 20px auto;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-family: "IBM Plex Sans";
    font-size: 32px;
    font-weight: 400;
    text-align: ce;
}


.dot {
    width: 16px;
    height: 16px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-16px);
    }
  }