.header-wrapper {
    min-height: 56px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
    .translate-from {
        display: flex;
        width: 50%;
        justify-content: start;
        padding-left: 15px;
    }
    .source-lang {
        border-color: transparent;
        cursor: pointer;
        color: #414141;
        font-family: Barlow;
        font-size: 20px;
        font-weight: 500;
    }
    .source-lang:focus {
        outline: none;
    }
    .source-lang:hover {
        background-color: #F2F4F7;
        border-radius: 5px;
    }
    .target-lang {
        border-color: transparent;
        cursor: pointer;
        color: #414141;
        font-family: Barlow;
        font-size: 20px;
        font-weight: 500;
    }
    .target-lang:focus {
        outline: none;
    }
    .target-lang:hover {
        background-color: #F2F4F7;
        border-radius: 5px;
    }
    .translate-to {
        display: flex;
        width: 50%;
        justify-content: space-between;
        padding-left: 5px;
        .translate-btn-wrapper {
            padding-right: 20px;
        }
    }
    .switch-lang-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: -20px;
    }

}