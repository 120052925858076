.email-sent {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    width: 100%;
}

.email-sent-success-text {
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    font-size: 26px;
    font-weight: 500;
    margin: 30px auto 0 auto;
}

.check-email-btn {
    border: none;
    color: #FFF;
    background-color: #1E9DF2;
    padding: 15px 50px;
    border-radius: 2px;
    margin: 30px auto 10px auto;
    display: block;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.check-email-title {
    font-family: "IBM Plex Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 30px;
}