.header-main-wrapper {
    min-height: 60px;
    width: 100%;
    font-family: 'Barlow';
    color: #FFF;
    background-color: #308EC3;
}

.header-main-container {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1200px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.header-main-title {
    cursor: pointer;
}

.title-main-bold {
    font-size: 32px;
    font-weight: 900;
}

.title-main-standard {
    font-weight: 300;
    font-size: 32px;
}

.header-main-actions {
    display: flex;
    gap: 60px;
}

.dashboard-wrapper, .logout-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
}
