.reset-pass-form {
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    padding: 60px;
    border-radius: 30px;
    background: rgba(255, 255, 255, .2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    max-width: 400px;
}

.form-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.email-input {
    height: 40px;
    background-color: #D9D9D9;
    padding-left: 40px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 10px;
    border: 1px solid #D1D2D2;  
    min-width: 300px;
    width: calc(100% - 40px);
}

.reset-pass-title {
    font-family: "IBM Plex Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}

.reset-btn-wrapper {
    width: 100%;
}

.reset-btn {
    border: none;
    color: #FFF;
    background-color: #1E9DF2;
    padding: 15px 50px;
    border-radius: 2px;
    margin: 30px auto 10px auto;
    display: block;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.reset-input-label-text {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 25px 0 5px 0;
    margin: 0;
}