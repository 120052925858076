.login-wrapper {
    font-family: "IBM Plex Sans";
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-in-loading-wrapper {
    min-width: 300px;
}

.sign-in-loader-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}

input {
    outline: none;
}

.page-title {
    z-index: 1;
}

.company {
    color: #FFF;
    font-size: 140px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
}

.title-text {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 10px;
}

.login-form {   
    z-index: 1;
    width: 100%;
    padding: 60px;
    border-radius: 30px;
    background: rgba(255, 255, 255, .2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    min-height: 400px;
    max-width: 400px;
}
.login-input-label-text {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 25px 0 5px 0;
    margin: 0;
}


.form-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.title {
    font-family: "IBM Plex Sans";
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: auto;
}

.usernameOrEmail-wrapper {
    position: relative;
    min-width: 300px;
    width: 100%;

}

.login-password-wrapper {
    position: relative;
    min-width: 300px;
    width: 100%;
}

.username-or-email-input {
    height: 40px;
    background-color: #D9D9D9;
    padding-left: 40px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 10px;
    border: 1px solid #D1D2D2;  
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

.username-or-email-input:-webkit-autofill,
.username-or-email-input:-webkit-autofill:focus,
.login-password-input:-webkit-autofill,
.login-password-input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s !important;
}

.login-password-input {
    height: 40px;
    background-color: #D9D9D9;
    padding-left: 40px;
    margin-top: 5px;
    min-height: 30px;
    border-radius: 2px;
    border: none;
    border-radius: 10px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

.remember-me-wrapper {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.remember-me {
    color: #FFF;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    margin: 25px 10px 5px 0;
}

.no-accout-par {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.forgot-pass-btn {
    color: #308EC3;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
}

.sign-up-btn {
    color: #308EC3;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
}

.login-btn-wrapper {
    width: 100%;
}

#rememberMe {
    accent-color: #1E9DF2;
}

.username-label, .login-password-label {
    position: relative;
}
  
.username-label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background: url('../../assets/icons/user-circle-svgrepo-com.svg') no-repeat;
}

.login-password-label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background: url('../../assets/icons/passwordIcon.svg') no-repeat;
}

@media screen and (min-width: 769px) {
    .login-content-wrapper {
        display: flex;
        align-items: center;
        margin-top: auto;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }
}